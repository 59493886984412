import React from "react"
import {
  Layout,
  SEO,
  HomeBanner,
  FeaturedProjects,
  Status,
  GeneralServices,
  AdditionalServices,
  HomeAbout
} from "~/components"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      description="DGO Construction is a cebu based construction company. We always ascertain of carrying out satisfiable and quality output from the work that we provide."
    />
    <HomeBanner />
    <FeaturedProjects />
    <Status />
    <GeneralServices />
    <AdditionalServices buttonAreaDisplay={true} />
    <HomeAbout />
  </Layout>
)

export default IndexPage
